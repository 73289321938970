@import "./_fonts.scss";
@import "./_colors.scss";

@mixin color-box {
    height: 2vh;
    width: 2vh;
    margin-left: 2vh;
    font-weight: 500;
}

.risk_level_container {
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 3vh 4vw 5vh;
    box-sizing: border-box;
    min-height: 600px;
    max-width: 800px;

    color: black;

    align-self: center;
    margin: 30px auto;
    font-size: 22px;
    font-family: Heebo-Light;
    cursor: default;

    text-align: start;
    width: 55%;
}

.risk_level_title {
    font-family: Heebo-Medium;
    font-size: 25px;
    color: $main-blue;
    text-align: start;
    margin: 18px 0;
}

.title_center {
    text-align: center;
}

.risk-level-bold {
    font-weight: bold;
}

.popup-header {
    text-align: center;
}

.popup-body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.popup-button {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
}

.popup-excel {
    @extend .popup-button;
    text-decoration: none;
    min-width: 120px;

    padding: 10px 0;
    margin: 10px;
    background: $main-blue 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: hidden;
    border-radius: 10px;
    opacity: 1;
    color: white;
    font-size: 20px;
    font-family: Heebo-Light;
    cursor: pointer;

    &:focus {
        outline: black 2px solid;
        background-color: lighten($main-blue, $amount: 6);
    }

    &:hover {
        background-color: lighten($main-blue, $amount: 6);
    }
}

.popup-img {
    width: 4vh;
}

.risk_level_info {
    color: black;
    font-size: 13px;
}

.evaluation {
    font-family: Heebo-Medium;
    font-size: 16px;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    font-weight: 700;
}

.evaluation_score {
    height: 43%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-weight: initial;
    margin-top: 2vh;

    .evaluation_score_number {
        font-family: Heebo-Medium;
        margin: 0px;
        font-size: 25px;
        color: rgb(255, 110, 134);
    }
}

.risk_level_header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    color: black;
    font-size: 16px;
    font-weight: 600;
}

.header_icons {
    .header_icon {
        height: 6vh;
        margin-left: 2vh;
    }
}

.risk_level_sub_title {
    font-family: Heebo-Medium;
    font-size: 20px;
    color: $main-blue;
    text-align: start;
    margin: 18px 0;
}

.risk_level_feedback_container,
.risk_level_suggestion_container {
    font-family: Heebo-light;
    font-weight: 600;
    font-size: 15px;
    background-color: #f3f3f3;
    color: black;
    text-align: start;
    box-sizing: border-box;
    margin: 19px 0 24px 0;
    padding: 1vh 2vh;
    box-shadow: 0px 2px 6px #0000003d;
    .no_list_style {
        list-style: none;
    }

    .risk_level_feedback_info {
        font-weight: initial;

        .marker {
            padding: calc(0.1vw + 0.2vh);
            margin-top: 8px;
            height: 0;
            width: 0;
            border-radius: 50%;
            background-color: black;
            align-self: flex-start;
        }
    }
}

.suggestion_container {
    position: relative;
    padding-bottom: 40px;

    .suggestion_button {
        height: 40px;
        background: $main-blue 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: hidden;
        border-radius: 10px;
        opacity: 1;
        color: white;
        font-size: 20px;
        cursor: pointer;

        position: absolute;
        bottom: 0;

        padding: 0 calc(2vw + 2px);

        &:focus {
            outline: black 2px solid;
            background-color: lighten($main-blue, $amount: 6);
        }

        &:hover {
            background-color: lighten($main-blue, $amount: 6);
        }

        &:disabled {
            background-color: $medium-gray;
        }
    }
}

.risk_level_feedback_container {
    .risk_level_feedback_container_header {
        font-size: 14px;
    }

    .show_more {
        padding: 0;
        border: none;
        font-family: Heebo-Bold;
        font-size: inherit;
        color: $main-blue;
        text-decoration: underline;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }
}

.feedback_answer_divide {
    height: 30vh;
    min-height: fit-content;

    .pie-statistics-container {
        .bar_graph {
            height: 90%;
            width: 70%;
            display: flex;
            align-items: flex-end;
            justify-content: space-around;
            border-bottom: 2px solid black;
            margin-right: 40%;
            .bar {
                width: 15%;
            }
        }
    }
}

.feedback_link {
    font-size: 16px;
    text-align: end;
    display: block;
    margin-top: 10px;
}

.pie-statistics-container {
    display: flex;
    align-items: center;
    // background-color: blueviolet;
    justify-content: center;
    height: 90%;
    width: 70%;
    margin-right: 30%;

    .pie_statistics {
        width: 45vh;
        height: 45vh;
        border-radius: 50%;
    }
}
.color-box-container-up {
    bottom: 90%;
}
.color-box-container {
    position: relative;
    bottom: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .color-box-red {
        @include color-box;
        background-color: rgb(255, 118, 118);
    }

    .color-box-light-green {
        @include color-box;
        background-color: rgba(150, 176, 40, 0.8);
    }

    .color-box-dark-green {
        @include color-box;
        background-color: rgba(50, 176, 40, 0.8);
    }

    .color-box-yellow {
        @include color-box;
        background-color: rgba(250, 176, 40, 0.8);
    }
}

li {
    font-size: 13px;
}

#pdf {
    position: absolute;
    right: 100vw;
    width: 446px;
    top: -100%;
    padding: 3vh 1.3vw;
}

.li-with-bullet {
    display: flex;
    gap: 1vw;
    align-items: center;
}

.feedback_button {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .feedback_text{
        font-size:15.2px;
    }
    
    button {
        margin-top: 0.7vh;
        font-family: Heebo-Medium;
        font-size:16px;
        padding: 11px 20px;
        color: white;
        border-radius: 12px;
        border: none;
        background: $main-blue 0 0 no-repeat padding-box;
        width: fit-content;
        cursor: pointer;
        outline: none;
    }
}

@media screen and (max-width: 850px) {
    #pdf {
        padding: 3vh 4vw;
    }

    .risk_level_container {
        width: 90vw;
    }

    .pie-statistics-container {
        margin-right: unset;
        width: 100%;
        height: 120%;

        .pie_statistics {
            width: 35vh;
            height: 35vh;
        }
    }

    .feedback_answer_divide {
        .pie-statistics-container {
            .bar_graph {
                height: 80%;
                margin-bottom: 20%;
                width: 60%;
                margin-right: 50%;
            }
        }
    }
    .color-box-container {
        bottom: 112%;
    }

    .popup-button {
        flex-direction: column-reverse;
    }
}
