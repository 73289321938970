$main-blue: #211333;
$main-blue: #1E0868;


$background-gray: #eeeeee;
$gray: #c9c4c4;
$medium-gray: #756e67;
$answer-light: #f5f5f5;
$answer-dark: #c8c8c8aa;


//brown
// $main-blue: #944910;
// $medium-gray:#91877d;
// $answer-light:#FDF1E9;
// $answer-dark:#e9dcd2;


//red
// $main-blue: #a70b0b;
// $medium-gray:#917d7d;
// $answer-light:#fde9e9;
// $answer-dark:#e9d2d2;

//blue
$main-blue: #05668d;
$medium-gray:#7d8e91;
$answer-light:#edf6f8;
$answer-dark:#d2e4e9;

