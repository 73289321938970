@import "./_fonts.scss";
@import "./_colors.scss";

.feedback_answer_box {
    .answer_button {
        background-color: $answer-light;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        opacity: 1;
        margin: #{"min(1vh, 1vw)"} 0 #{"min(1.5vh, 1.5vw)"} 0;
        padding: #{"min(2vh, 2vw)"} #{"min(2vw, 2vh)"};
        color: $medium-gray;
        font-size: 1em;
        min-width: 22.5%;
        max-width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        box-sizing: border-box;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        -webkit-user-select: none;

        &:focus-visible {
            background-color: $answer-dark;
        }
    }

    .answer_button::-webkit-outer-spin-button,
    .answer_button::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    width: 80%;
    font-size: 14px;
    font-family: Heebo-Regular;
    display: flex;
    flex-wrap: nowrap;

    .active {
        background-color: $answer-dark;
        font-weight: 600;
        color: $main-blue;
    }
}