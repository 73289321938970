@import "./_fonts.scss";
@import "./_colors.scss";

.home_inner_bar_container {
    width: 90%;
    display: flex;
    margin: 4vh 0;

    .title {
        color: $main-blue;
        color: #43555b;
        font-size: 33px;
        font-family: Heebo-Bold;
        margin: 0;
    }

    .logo{
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 850px) {
    .home_inner_bar_container {
        margin: 2vh 0;
        .title {
            text-align: center;
            font-size: vh(5);
            margin-top: 5px;
        }
    }
}

@media screen and (max-width: 670px) {
    .home_inner_bar_container {
        .title {
            font-size: 30px;
        }
    }
}

@media screen and (max-width: 550px) {
    .home_inner_bar_container {
        .title {
            font-size: 22px;
            padding-left: 10px;
        }
    }
}
