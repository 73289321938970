@import "./_colors.scss";
@import "./_fonts.scss";

.home_text_container {
    position: relative;
    color: #1c1c1c;
    width: 90%;
    min-height: 57vh;
    padding: 4% 4% 8% 4%;
    font-size: 15px;
    font-family: Heebo-Light;

    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;

    box-sizing: border-box;

    .text:not(:last-child) {
        margin-bottom: 20px;
    }

    .button_container {
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: calc(-4px - 3vh);

        button {
            font-family: Heebo-Medium;
            font-size: 4vh;
            padding: 4px 40px;
            color: white;
            border-radius: 10px;
            border: none;
            background: $main-blue 0 0 no-repeat padding-box;

            width: fit-content;
            cursor: pointer;

            outline: none;

            &:focus {
                outline: black 2px solid;
                background: $main-blue 0 0 no-repeat padding-box;
            }
            &:hover {
                background: $main-blue 0 0 no-repeat padding-box;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .home_text_container {
        font-size: 18px;
        .button_container {
            bottom: calc(-3px - 2.5vh);
            button {
                padding: 3px 30px;
                font-size: 28px;
            }
        }
    }
}

@media screen and (max-width: 670px) {
    .home_text_container {
        font-size: 14px;
    }
}

@media screen and (max-width: 550px) {
    .home_text_container {
        .button_container {
            bottom: -20px;
            button {
                padding: 3px 30px;
                font-size: 22px;
            }
        }
    }
}
