@import "./_fonts.scss";
@import "./_colors.scss";

.questionnaire_container {
    height: 85vh;
    display: flex;
    flex-direction: row;
}
.questionnaire-title {
    height: 15vh;
    color: #43555b;
    font-weight: 600;
    font-size: 5vh;
    font-family: Heebo-Regular;
    display: flex;
    align-items: center;
    justify-content: center;
}

.questionnaire_inner_container {
    margin-right: 10%;
    padding-bottom: 5vh;
    width: 63vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.question_header {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    font-family: Heebo-Regular;
    font-size: 1.2em;
    color: $main-blue;
    color: #43555b;
    margin-bottom: 2vh;
}

.question_container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 3vh 4vw;
    box-sizing: border-box;

    font-family: Heebo-Light;
    cursor: default;

    text-align: center;
    min-height: 70vh;
    .question {
        display: block;
        color: $main-blue;
        width: 95%;
        font-family: Heebo-Regular;
        font-size: 1em;
        padding-bottom: 1vh;
        cursor: default;
        text-align: start;
    }

    .back_and_next_buttons {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        .next_button {
            height: 50px;
            background: $main-blue 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: hidden;
            border-radius: 10px;
            opacity: 1;
            color: white;
            font-size: 22px;
            cursor: pointer;
            padding: 0 3.5vw;

            &:focus {
                outline: black 2px solid;
                background-color: lighten($main-blue, $amount: 6);
            }
            &:hover {
                background-color: lighten($main-blue, $amount: 6);
            }

            &:disabled {
                // background-color: $medium-gray;
                opacity: 0.2;
                cursor: not-allowed;
                background-color: lighten($main-blue, $amount: 6);
            }
        }

        .previous_button_container {
            display: flex;
            align-items: center;
            gap: 1vw;
            width: 9%;
            justify-content: space-between;
            font-weight: 600;
            font-size: 22px;
            button {
                padding: 0;
                border: none;
                background-color: transparent;
                .previous_button {
                    border: 2px solid $main-blue;
                    box-sizing: border-box;
                    border-radius: 40px;
                    color: $main-blue;
                    font-size: 35px;
                    cursor: pointer;
                    margin: auto 0;
                    -webkit-tap-highlight-color: transparent;

                    &:focus {
                        outline: none;
                    }

                    &:disabled {
                        background-color: $medium-gray;
                    }
                }
            }
        }
    }
}

.progress_container {
    width: 20vw;
    height: fit-content;
    top: 21%;
    position: fixed;
    left: 0;
}

.answer_container {
    text-align: right;
}

::-webkit-scrollbar {
    // position: relative;
    // right: 0;
    direction: ltr;
    width: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d2d3d3;
}

@media screen and (max-width: 850px) {
    .questionnaire_inner_container {
        width: 85vw;
        margin-right: 7.5%;
    }

    .question_header {
        margin-top: 4vh;
        flex-wrap: wrap;
        font-size: 1.1em;
    }

    .progress_container {
        transform: rotateZ(90deg);
        width: unset;
        left: 90vw;
        position: absolute;
        top: 14vh;
        height: 0;
    }
}
