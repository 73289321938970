@import "./_fonts.scss";
@import "./_colors.scss";

.answer_button {
    background-color: $answer-light;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    align-items: center;
    height: fit-content;
    margin: #{"min(1vh, 1vw)"} 0 #{"min(1.5vh, 1.5vw)"} 0;
    padding: #{"min(2vh, 2vw)"} #{"min(2vw, 2vh)"};
    color: $medium-gray;
    font-size: 1em;
    min-width: 22.5%;
    max-width: 30%;
    display: flex;
    justify-content: space-between;
    border: none;
    text-align: right;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    user-select: none;
    -webkit-user-select: none;

    &:focus-visible {
        background-color: $answer-dark;
    }
}

.answer_button::-webkit-outer-spin-button,
.answer_button::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.select-container {
    position: relative;
    width: 100%;

    .age-select {
        appearance: none;
    }
    .select-img {
        width: 2%;
        position: absolute;
        right: 25%;
        top: 35%;
    }
}

.answer_box {
    width: 100%;
    font-size: 14px;
    font-family: Heebo-Regular;
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
    .active {
        background-color: $answer-dark;
        font-weight: 600;
        color: $main-blue;
    }
    .check_icon_div {
        opacity: 0;
    }
    .active > .check_icon_div {
        opacity: 1;
        margin: auto 0;
    }
}

.container-for-autocomplete {
    margin-bottom: 20px;
    background-color: #edf6f8;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    width: 100%;
    .MuiAutocomplete-endAdornment {
        left: 1vw;
        right: auto;
    }
    .MuiAutocomplete-option {
        direction: rtl;
    }
}

@media screen and (max-width: 850px) {
    .answer_box {
        font-size: 18px;
        flex-direction: column;

        .answer_button {
            max-width: unset;
        }
    }
}

@media screen and (max-width: 670px) {
    .answer_box {
        font-size: 14px;
    }
}
