@import "./_colors.scss";
@import "./_fonts.scss";

.generic_popup_page_container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.411);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;

    button {
        min-width: 120px;
        padding: 10px 0;
        margin: 10px;
        border-radius: 10px;
        opacity: 1;
        color: white;
        font-size: 20px;
        font-family: Heebo-Light;
        font-weight: 600;
        cursor: pointer;
        &:focus {
            outline: black 2px solid;
            background-color: lighten($main-blue, $amount: 6);
        }

        &:hover {
            background-color: lighten($main-blue, $amount: 6);
        }
    }

    .report_popup_return_button {
        background-color: white;
        color: $main-blue;
        border-width: 0.1vh;
        border: solid;
        &:hover {
            background-color: white;
            
        }
    }
    
    .report_popup_finish {
        background: $main-blue 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: hidden;
    }

}

.generic_popup_container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 10px 20px;
    box-sizing: border-box;
    width: 400px;
    max-height: 80vh;
    align-self: center;
    margin: 30px auto;
    font-size: 22px;
    font-family: Heebo-Light;
    cursor: default;
    display: flex;
    flex-direction: column;
}

::-webkit-scrollbar {
    display: none;
}

.generic_popup_text_container {
    display: block;
    padding: 20px;
    flex: 1;
    text-align: center;
    font-weight: 600;
}

.popup-close-button {
    font-weight: 600;
    width: fit-content;
    cursor: pointer;
    transform: rotate(45deg);
}