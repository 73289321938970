.loading_page_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    z-index: 9;
    // background-color: rgba(255, 255, 255, 0.411);
    // backdrop-filter: hue-rotate(240deg) blur(10px);

    img {
        margin: 0 auto;
        width: 50vw;
        max-width: 150px;
        user-select: none;
        -webkit-user-select: none;
        -webkit-user-callout: none;
        -webkit-touch-callout: none;
    }
}

.loading_page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}