@import "./_colors.scss";
@import "./_fonts.scss";

.return_popup_button_container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    &:last-child {
        justify-self: flex-end;
    }
}
