@font-face {
    font-family: 'Heebo-Light';    
    src: url('../fonts/Heebo/Heebo-Light.ttf');
}

@font-face {
    font-family: 'Heebo-Regular';    
    src: url('../fonts/Heebo/Heebo-Regular.ttf');
}

@font-face {
    font-family: 'Heebo-Medium';
    src: url('../fonts/Heebo/Heebo-Medium.ttf');
}

@font-face {
    font-family: 'Heebo-Bold';
    src: url('../fonts/Heebo/Heebo-Bold.ttf');
}

@font-face {
    font-family: 'GveretLevin';//הכתב יד - for you...
    src: url('../fonts/GveretLevin.ttf')
}

@font-face {
    font-family: 'Viga-Regular';
    src: url('../fonts/Viga/Viga-Regular.ttf');
}