@import "./_fonts.scss";
@import "./_colors.scss";

.progress_bar_container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .progress_bar {
        background-color: $answer-dark;
        width: 6px;
        height: 60vh;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .progress_part_container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .progress_part {
                height: 14px;
                width: 14px;
                z-index: 3;
                border-radius: 50%;
                transition-duration: 1s;
                transition-delay: 0.3s;
            }

            .progress_part_text {
                position: absolute;
                width: 15vw;
                margin-right: 2vw;
                font-size: 0.8rem;
                font-family: Heebo-Regular;
            }
        }
        .progress_fill {
            background: $main-blue;
            position: absolute;
            width: 6px;
            transition: height 0.3s linear;
            background-color: hsl(194, 12%, 47%);
            border-radius: 20px;
        }
    }
    .progress_percentage {
        flex-shrink: 2;
        position: relative;
        font-family: Heebo-Regular;
        font-size: 17px;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }
}

@media screen and (max-width: 850px) {
    .progress_bar_container {
        .progress_bar {
            height: 80vw;
        }
    }
    .progress_part_text {
        visibility: hidden;
    }
}
