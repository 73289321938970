@import "./_fonts.scss";

.contact_info_header {
    display: flex;
    justify-content: space-between;
    margin-top: 1vh;
    margin-bottom: 2vh;
    .search {
        display: flex;
        align-items: center;
        border: solid 1px grey;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 24px;
        border-radius: 7vw;
        text-align: right;
        width: fit-content;
        padding-right: 1vw;
        input {
            font-size: 1.7777vh;
            height: 3vh;
            // width: 17.777vh;
            // width: 20%;
            border: none;
            background-color: #f3f3f3;
            &:focus {
                outline: none;
                border: none;
                border-color: none;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }
        .search_bar_img {
            height: 2vh;
            // margin-top: 0.7vh;
            margin-left: 0.6vh;
            margin-right: 1vh;
        }
    }
}
.contact_info_table {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    color: black;
    :first-child {
        flex: 1;
    }

    :nth-child(2) {
        flex: 2;
    }
    :nth-child(3) {
        flex: 2;
    }
}

.column_in_table {
    padding-right: 0.5vw;
    // background-color: blue;
    // font-size: calc(0.6vw+1.155vh);
    font-size: 2.3vh;
    font-weight: 700;
    width: 25%;
}

.scroll_box_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    direction: ltr;
    max-height: 20vh;
    overflow-y: scroll;
    margin-bottom: 1.4vh;

    .row_container {
        font-family: Heebo-light;
        display: flex;
        width: 100%;
        height: fit-content;
        justify-content: space-between;

        :first-child {
            flex: 2;
        }

        :nth-child(2) {
            flex: 2;
        }
        :nth-child(3) {
            flex: 1;
        }

        h3 {
            direction: rtl;
            // font-size: calc(0.5vw + 0.8888vh);
            font-size: 1.7777vh;
            font-weight: lighter;
            width: 25%;
            margin-bottom: 0.5vh;
        }
    }
}
@media screen and (max-width: 850px) {
    #pdf {
        .row_container {
            flex-direction: row;
        }
    }

    .search {
        height: 30px;
        input {
            width: 12vh;
        }
    }

    .title_for_phone {
        font-size: 2.5vh;
    }

    .row_container {
        width: 90%;
        flex-direction: column-reverse;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 1vh;
        margin-bottom: 1vh;

        div {
            font-size: 2.2vh;
            font-weight: lighter;
            margin-bottom: 1.5vh;
        }
    }

    .contact_info_row {
        text-align: right;
        width: 100%;
        .email_img {
            width: 20px;
            margin-left: 3vw;
        }
        .phone_img {
            width: 20px;
            margin-left: 3vw;
        }
    }
}
