@import "./_fonts.scss";
@import "./_colors.scss";

.questionnaire_container {

    .questionnaire_inner_container {
        margin-right: 10%;
        padding-bottom: 5vh;
        width: 63vw;
        height: fit-content;
        display: flex;
        flex-direction: column;
    }

    .question_header {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        font-family: Heebo-Regular;
        font-size: 1.2em;
        color: $main-blue;
        margin-bottom: 2vh;
        }

    .question_container {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        padding: 3vh 4vw;
        box-sizing: border-box;

        font-family: Heebo-Light;
        cursor: default;

        text-align: center;
        min-height: 70vh;

        .back_and_next_buttons {
            width: 54vw;

            .next_button {
                height: 50px;
                background: $main-blue 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: hidden;
                border-radius: 10px;
                opacity: 1;
                color: white;
                font-size: 22px;
                cursor: pointer;
                padding: 0 3.5vw;

                &:focus {
                    outline: black 2px solid;
                    background-color: lighten($main-blue, $amount: 6);
                }

                &:hover {
                    background-color: lighten($main-blue, $amount: 6);
                }

                &:disabled {
                    opacity: 0.2;
                    cursor: not-allowed;
                    background-color: lighten($main-blue, $amount: 6);
                }
            }
        }

        .input-field {
            width: 52vw;
            margin-left: 2vw;
            height: 20vh;
            background-color: $answer-light;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            opacity: 1;
            color: $medium-gray;
            font-size: 1.3em;
            border: none;
            font-family: Heebo-Regular;
            padding: 1vh 2vh;
            outline: none;
            resize: none;

            &::placeholder {
                opacity: 50%;
                font-size: 1em;
                font-family: Heebo-Regular;

            }
        }
    }

    ::-webkit-scrollbar {
        direction: ltr;
        width: 10px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #d2d3d3;
    }

}

@media screen and (max-width: 850px) {
    .questionnaire_inner_container {
        width: 85vw;
        margin-right: 7.5%;
    }

    .question_header {
        margin-top: 4vh;
        flex-wrap: wrap;
        font-size: 1.1em;
    }
}