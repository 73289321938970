.home_top_bar_container {
    position: relative;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw;
    justify-content:space-between;
    width: 60%;

    img {
        height: 8vh;
        margin: 15px 15px 0 15px;
    }
    .welfare_image{
        height: 10vh;
    }
}

@media screen and (max-width: 850px) {
    .home_top_bar_container {
        min-height: 28px;
        flex-wrap: wrap;
        width: 80%;
        img {
            height: 8vh;
            margin: 10px 10px 0 10px;
        }
        .welfare_image{
            height: 10vh;
        }
    }
}
